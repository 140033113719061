@use './Global.scss' as *;

.rsvp {
    width: 50%;

    // Dont do anything 
    @media (max-width: $breakpoint-lg) {
        width: 100%;
    }

    iframe {
        width: 100%;
        height: calc(100% + 4rem);
        border: none;
        transform: translateY(-2rem);
    }
}