@use './Global.scss' as *;

$inactive-color: #b2b2b2;
$menu-button-border-width: 0.15rem;

.menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 1rem 1rem;
    border: none;
    border-bottom: $menu-button-border-width solid adjust-color($color: $inactive-color, $alpha: -0.5);
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    color: darken($color: $inactive-color, $amount: 10%);
    font-weight: 500;
    font-size: 0.9rem;
    text-align: center;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &.active {
        color: $primary-color;
        border-bottom: $menu-button-border-width solid adjust-color($color: $primary-color, $alpha: -0.5);
    }

    // Center when the screen is small
    @media (max-width: $breakpoint-lg) {
        justify-content: center;
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }

    .menu-text {
        transition: all 0.5s cubic-bezier(.17,.67,.08,.99);
    }

    &:hover {
        border-bottom: $menu-button-border-width solid $primary-color;
        
        // Move the text up a bit
        .menu-text {
            transform: translateY(-0.3rem);
        }
    }
}