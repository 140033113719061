@use './Global.scss' as *;

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .home-title {
        margin: 0 !important;
        margin-bottom: 1rem !important;
        transform: translateY(-0.5rem);
        color: $primary-color;
        font-size: 1.5rem;
    }

    .home-content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        max-width: 50%;
        gap: 1rem;

        @media (max-width: $breakpoint-lg) {
            max-width: 100%;
        }

        .home-img-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            min-width: 25vw;
            max-width: 50%;

            @media (max-width: $breakpoint-sm) {
                max-width: 35%;
                display: none;
            }

            .home-img {
                width: 100%;
                border-radius: 1rem;
                box-shadow: 0.0rem 0.5rem 1rem 0rem rgba(0, 0, 0, 0.3);
                background-color: rgba(0,0,0,0.1);
            }
        }

        $bubble-color: #55a2f5;
        .bubble-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            row-gap: 1rem;
            max-width: 50%;

            @media (max-width: $breakpoint-sm) {
                max-width: 100%;
                font-size: 0.9rem;
                padding-left: 4.5rem;
            }

            .text-bubble {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0.75rem;
                border-radius: 0.5rem;
                background-color: $bubble-color;
                color: white;
                font-weight: 500;
                line-height: 25px;
                box-shadow: 0.0rem 0.25rem 0.45rem 0rem rgba(0, 0, 0, 0.25);

                @media (max-width: $breakpoint-sm) {
                    line-height: 25px;
                }

                .bubble-img {
                    position: absolute;
                    top: 0;
                    left: -4.5rem;
                    width: 3rem;
                    height: 3rem;
                    margin-right: 0.5rem;
                    border-radius: 50%; 
                    box-shadow: 0.0rem 0.25rem 0.45rem 0rem rgba(0, 0, 0, 0.3);
                    display: none; 

                    @media (max-width: $breakpoint-sm) {
                        display: block;
                    }                
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0.5rem;
                    left: -0.6rem;
                    width: 0;
                    height: 0;
                    border-top: 1rem solid transparent;
                    border-bottom: 1rem solid transparent;
                    border-right: 1rem solid $bubble-color;
                }
            }
        }
    }
}