@use './Global.scss' as *;

.registry {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    .title {
        font-weight: normal;
        font-size: 1.5rem;
        text-align: center;
    }

    .secret-text {
        font-weight: 500;
        font-size: 0.9rem;
        color: adjust-color($color: $text-color, $alpha: -0.5);
        text-align: center;
        margin-top: 1rem;
        text-align: center;
    }

    .options-button {
        justify-self: center;
        font-weight: 500;
        font-size: 0.9rem;
        color: $text-color;
        text-decoration: none;
        cursor: pointer;
        background-color: rgba(255,255,255,0.8);
        border-radius: 1rem;
        padding: 0.5rem 1rem;
        border: 1px solid rgba(0,0,0,0.1);
        margin-top: 0.5rem;
        box-shadow: 0.0rem 0.1rem 0.5rem 0rem rgba(0, 0, 0, 0.1);
        transition: all 0.5s cubic-bezier(.17,.67,.08,.99);

        &:hover {
            color: $primary-color;
            background-color: rgba(255,255,255,1);
            border: 1px solid adjust-color($primary-color, $alpha: -0.5);
            box-shadow: 0.0rem 0.5rem 0.75rem 0rem rgba(0, 0, 0, 0.1);
            transform: scale(1.025);
        }
    }

    .donate-container {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: 1rem;

        @media (max-width: $breakpoint-lg) {
            flex-direction: column;
        }

        .donate-img-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 1rem;
            margin-top: 2rem;

            .name {
                font-weight: 500;
                font-size: 1.1rem;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                .link {
                    font-weight: 500;
                    font-size: 0.9rem;
                    color: $text-color;
                    text-decoration: none;
                    cursor: pointer;
                    background-color: rgba(255,255,255,0.8);
                    border-radius: 1rem;
                    padding: 0.5rem 1rem;
                    margin-left: auto;
                    border: 1px solid rgba(0,0,0,0.1);
                    box-shadow: 0.0rem 0.25rem 0.5rem 0rem rgba(0, 0, 0, 0.1);
                    transition: all 0.5s cubic-bezier(.17,.67,.08,.99);
    
                    &:hover {
                        color: $primary-color;
                        background-color: rgba(255,255,255,1);
                        border: 1px solid adjust-color($primary-color, $alpha: -0.5);
                        box-shadow: 0.0rem 0.5rem 0.75rem 0rem rgba(0, 0, 0, 0.2);
                        transform: scale(1.025);
                    }
                }
            }

            .donate-img {
                width: 20rem;
                height: 20rem;
                border: 1px solid rgba(0,0,0,0.1);
                border-radius: 1.5rem;
                box-shadow: 0.0rem 0.5rem 1rem 0rem rgba(0, 0, 0, 0.2);
            }
        }
    }
}