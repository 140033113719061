@use './Global.scss' as *;

.caption-image {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0.0rem;
    //border: 3px dashed $primary-color;
    border-radius: 2rem;
    //overflow: hidden;
    //box-shadow: 0.0rem 0.5rem 1rem 0rem rgba(0, 0, 0, 0.3);
    gap: 1rem;
    //transition: all 0.5s cubic-bezier(.17,.67,.08,.99);

    img {
        display: flex;
        border-radius: 1rem;
        box-shadow: 0rem 0rem 0.0rem 0.3rem adjust-color($color: $text-color, $alpha: -0.85);
        transform: scale(0.95);
        cursor: zoom-in;
        transition: all 0.5s cubic-bezier(.17,.67,.08,.99);
    }

    .caption {
        position: relative;
        color: $text-color;
        font-size: 1rem;
        font-weight: 500;
        padding: 1rem;

        &::after {
            content: '';
            position: absolute;
            bottom: 0rem;
            top: 0rem;
            left: 0rem;
            border-left: 3px dashed $primary-color;
        }
    }

    &.zoomed {
        width: 50% !important;
        max-width: 100% !important;

        .img {
            width: 100% !important;
        }

        .caption {
            display: none;
    
            &::after {
                content: none;
            }
        }

        // Dont do anything 
        @media (max-width: $breakpoint-lg) {
            width: auto !important;

            .caption {
                display: block !important;
                position: relative;
                color: $text-color;
                font-size: 1rem;
                font-weight: 500;
                padding: 0rem;
                padding-left: 0.5rem;
        
                &::after {
                    content: none;
                }
            }
        }
    }

    @media (max-width: $breakpoint-lg) {
        flex-direction: column;
        max-width: 100% !important;
        padding-left: 1rem;

        &::after {
            content: '';
            position: absolute;
            bottom: 0rem;
            top: 0rem;
            left: 0rem;
            border-left: 3px dashed $primary-color;
        }

        img {
            width: 100% !important;
        }

        .caption {
            position: relative;
            color: $text-color;
            font-size: 1rem;
            font-weight: 500;
            padding: 0rem;
            padding-left: 0.5rem;
    
            &::after {
                content: none;
            }
        }
    }
}