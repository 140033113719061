@use './Global.scss' as *;

$cole-img: '/public/ce_1.jpg';

.story {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 2rem;
    width: 100%;
    height: 100%;

    .event-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 60%;
        gap: 1rem;

        @media (max-width: $breakpoint-lg) {
            max-width: 100%;
        }

        .event-img-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 50%;
            position: relative;

            @media (max-width: $breakpoint-lg) {
                width: 100% !important;
            }

            img {
                width: 100% !important;
                border-radius: 1rem;
                box-shadow: 0.0rem 0.5rem 1rem 0rem rgba(0, 0, 0, 0.3);
            }

            .date {
                position: absolute;
                top: -0.25rem;
                right: -0.25rem;
                width: auto;
                padding: 0.5rem 1rem;
                background-color: rgb(31, 168, 106);
                color: white;
                font-weight: 500;
                text-align: center;
                border-radius: 0rem 1rem 0rem 1rem;
                box-shadow: -0.25rem 0.25rem 0.0rem 0rem rgba(0, 0, 0, 0.15);
            }
        }

        $bubble-color: #55a2f5;
        .bubble-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            row-gap: 1rem;
            width: 50%;
            max-width: 50%;

            @media (max-width: $breakpoint-lg) {
                width: 75%;
                max-width: 100%;
                font-size: 0.9rem;
                padding-left: 4.5rem;
            }

            .text-bubble {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0.75rem;
                border-radius: 0.5rem;
                background-color: $bubble-color;
                color: white;
                font-weight: 500;
                line-height: 25px;
                box-shadow: 0.0rem 0.25rem 0.45rem 0rem rgba(0, 0, 0, 0.25);

                .bubble-img {
                    position: absolute;
                    top: 0;
                    left: -4.5rem;
                    width: 3rem;
                    height: 3rem;
                    margin-right: 0.5rem;
                    border-radius: 50%; 
                    box-shadow: 0.0rem 0.25rem 0.45rem 0rem rgba(0, 0, 0, 0.3);
                    display: block;
                    
                    &.cole {
                        // Set background image
                        background-image: url(../../public/cole_profile.png);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 185%;
                        margin: 0;
                        padding: 0;
                    }

                    &.elli {
                        // Set background image
                        background-image: url(../../public/elli_profile.png);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 120%;
                        margin: 0;
                        padding: 0;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0.5rem;
                    left: -0.6rem;
                    width: 0;
                    height: 0;
                    border-top: 1rem solid transparent;
                    border-bottom: 1rem solid transparent;
                    border-right: 1rem solid $bubble-color;
                }
            }
        }
    }
}