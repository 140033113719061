@use './Global.scss' as *;

.layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .menu {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        min-height: 10rem;
        height: auto;
        padding: 0 2rem;
        flex-wrap: wrap;
        margin-top: 1rem;
        //gap: 1rem;

        // Center when the screen is small
        @media (max-width: $breakpoint-lg) {
            justify-content: center;
        }

        .title {
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            align-self: flex-end;
            font-size: 2.5rem;
            font-weight: 400;
            color: $primary-color;
            white-space: nowrap;
            width: 100%;

            // Center when the screen is small
            @media (max-width: $breakpoint-lg) {
                justify-content: center;
                font-size: 2rem;
            }

            .title-img {
                width: 6rem;
                height: 6rem;
                margin-right: 1rem;

                // Center when the screen is small
                @media (max-width: $breakpoint-lg) {
                    width: 4rem;
                    height: 4rem;
                }
            }
        }

        .menu-items {
            display: flex;
            flex-direction: row;
        }
    }

    $border-color: rgba(0, 0, 0, 0.075);
    $border-radius: 0.75rem;
    $border-width: 2px;
    .content-container {
        display: flex;
        flex: 1 1 auto; 
        padding: 1rem;

        .content {
            //border: $border-width solid $border-color;
            //border-radius: $border-radius;
            //box-shadow: 0.5rem 0.5rem 1rem 0rem rgba(0, 0, 0, 0.1);
            width: 100%;
            padding: 1rem;
            color: $text-color;
            display: flex;
            justify-content: center;
        }
    }
}