// Breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// General Colors
$white: #fff;
$primary-color: #3f77bf;
$primary-color-dark: darken($color: $primary-color, $amount: 10%);
$primary-color-bg: adjust-color($color: $primary-color, $alpha: -0.9);
$text-color: #484848;

h1, h2, h3, h4, h5 {
    margin: 0;
}

.home, .story, .venue, .registry, .rsvp {
    &.loading {
        display: none !important;
    }
}

@keyframes slide_in_bottom {
    0% {
        transform: translate(0%, 20%);
        opacity: 0;
        
    }
    100% {
        transform: translate(0%, 0%);
        opacity: 1;
    }
}
