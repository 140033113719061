.root {
    background-color: adjust-color($color: white, $alpha: -0.5);
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    overflow-y: auto;
}

.bg-img-container-1 {
    overflow: hidden;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: flex-start;
    align-items: flex-end;
    pointer-events: none;
    opacity: 0.2;
    z-index: -1;

    .castle-img {
        transform: translate(0rem, 20%);
        user-select: none;
        animation: slide_from_bottom_1 1s cubic-bezier(.5,.77,.47,1.00) forwards;
    }
}

.bg-img-container-2 {
    overflow: hidden;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: flex-end;
    align-items: flex-end;
    pointer-events: none;
    opacity: 0.4;
    z-index: -1;
    

    .fields-img {
        mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        transform: translate(0rem, 12%);
        width: 100vw;
        height: 60vw;
        user-select: none;
        animation: slide_from_bottom_2 0.75s cubic-bezier(.5,.77,.47,1.00) forwards;
    }
}

@keyframes slide_from_bottom_1 {
    0% {
        transform: translate(0%, 100%);
        
    }
    100% {
        transform: translate(0rem, 20%);
    }
}

@keyframes slide_from_bottom_2 {
    0% {
        transform: translate(0%, 100%);
        
    }
    100% {
        transform: translate(0rem, 12%);
    }
}