@use './Global.scss' as *;

.venue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    row-gap: 1rem;
    overflow: auto;
    
    @media (max-width: $breakpoint-lg) {
        row-gap: 2rem;
    }
}